// 英文 
const en = {
	language: {
		name: 'EngLish'
	},
	navbar: {
		home: 'home',
		introduction: 'introduction',
		contact: 'contact',

		//头部
		home:'home',    //首页
		fabric:'fabric',    //面料库
		buyingInformation:'buyingInformation',    //求购信息
		information:'information',    //资讯
		trend:'trend',    //流行趋势
		cotton:'Please enter the fabric name',    //棉花
		Login:'Login',		//登录
		joinClub: 'join in the  club',		//申请加入俱乐部
		ExpertRecruitment:'Fabric expert Recruitment',		//面料专家招募
		//登录
		AccountNumber:'Account Number',		//账号
		Password:'password',		//密码
		UserNameCannotEmpty:'The user name cannot be empty',	//用户名不能为空
		PasswordCannotEmpty:'The password cannot be empty',		//密码不能为空
		ForgetThePassword:'Forget the password',				//忘记密码
		RetrievePassword:'Retrieve Password',				//找回密码
		Service:'Service tel',	//请联系逐日客服
		Logout:'Are you sure you want to log out',			//确定退出登录吗
		Cancel:'cancel',		//取消
		Confirm:'confirm',		//确定


		//首页
		loadMore:'loadMore',    //加载更多

		//底部
		customerService:'CustomerService',    //客户服务
		FAQ:'FAQ',    //常见问题解答
		unsubscribe:'Unsubscribe',    //取消订阅
		contacts:'Contacts',    //联系人
		about:'About',    //关于
		BattenRecruitment:'BattenRecruitment',     //巴藤招聘
		BattenCampus:'BattenCampus',    //巴藤校园
		KungFuGeek:'KungFuGeek',    //功夫极客
		legislation:'Legislation',   //法律
		PrivacyPolicy:'PrivacyPolicy',    //隐私政策
		BrowserCachingPolicy:'BrowserCachingPolicy',    //浏览器缓存政策
		TermsAndConditions:'TermsAndConditions',    //条款与条件
		IOSVersionDownload:'IOS',     //IOS版下载
		AndroidVersionDownload:'Android',    //安卓版下载
		FabtankMiniProgram:'MiniProgram',
		SuICPFor14053618:'SuICPFor14053610',  

		//面料库
		ActualResult:'ActualResult',	//全部结果
		WovenGoods:'WovenGoods',    //梭织类
		hosiery:'hosiery',     //针织类
		SpecialTypes:'SpecialTypes',    //特殊类
		hairgrass:'hairgrass',    //毛草类
		Nonwovens:'Nonwovens',    //非织造类
		NoData:'No Data',		//暂无数据
		Loadingzhong:'Loading',		//加载中


		//详情页面
		back:'Return', //返回
        specification: 'Specification',   //规格
		theWeight:'Weight',   //克重
		theWidth:'Width',   //门幅 Larghezza
		theConst:'FabricCount',   //经纬密
		client:'Supplier',   //供应商
		periodOfValidity:'Validity',   //有效期
		LongTermEffective:'LongTermEffective',	//长期有效
		club:'Club',    //俱乐部
		shippingMethod:'Shipping',    //送货方式
		ByMutualAgreement:'Bilateral consultation',	//自行约定 By mutual agreement
		theContent:'Comp',   //原料成分  Composition
		structure:'FabricSorting',   //布料分类
		releaseTime:'ReleaseTime',    //发布时间
		ColorCardDownload:'Color card download',  //色卡下载
		inquiry:'Inquiry',   //我要询盘
		sample:'Sample',    //我要拿样
		detailedInformation:'Detail',    //详细信息
		reset:'Reset',   //重置
		Render3DEffect:'Render 3D',   //渲染3D效果
		Loading:'Loading',  //拼命加载中
		NewProduct:'New product of the same period',//同期新品
		ExplosiveGoods:'Explosive goods',//爆款商品
		ReadMore:'Read more', //浏览更多
		QualityCertificate:'Quality Certificate', //质量认证
		DeliverPlace:'DeliverPlace',	//发货地
		ProductionPlace:'ProductionPlace',	//原产地
		LogisticsMethods:'LogisticsMethods',	//物流方式
		LogisticsTerms:'LogisticsTerms',	//物流条款

		//我要询盘
		supertex:'KEQI Apparel',	
		ArticleNumber:'ArticleNumber',   //商品货号
		ExpectedOrderQuantity:'OrderQuantity',    //预期订货量（订货量）
		QuantityCannotEmpty:'The expected order quantity cannot be empty',//预期订货量不能为空
		ExpectedQuantity:'Please enter the correct expected order quantity', //请输入正确的预期订货量
		ExpectedPrice:'ExpectedPrice',    //预期价格
		PriceCannotEmpty:'The expected price cannot be empty', //预期价格不能为空
		PriceMustRoundNumber:'The expected price must be a round number', //预期价格必须为整数
		question:'Question',    //问题
			//我要拿样(下面部分)
		SupplementaryInformation:'Supplement',    //补充信息(补充)
		SupplementaryCannotEmpty:'The supplementary information cannot be empty',//补充信息不能为空
		contacts:'Contacts',    //联系人
		contactCannotEmpty:'The contact cannot be empty',//联系人不能为空
		companyName:'CompanyName',    //公司名称
		companyNameCannotEmpty:'The company name cannot be empty', //公司名称不能为空
		phone:'Phone',    //联系电话
		contactNumberCannotEmpty:'The contact number cannot be empty',//联系电话不能为空
		contactNumberMustNumber:'The contact number format is incorrect',//联系电话必须为数字(改为)联系电话格式错误
		address:'Address',    //地址
		addressCannotEmpty:'The address cannot be empty',  //地址不能为空
		faxes:'Faxes',     //传真
		EMAIL:'E-MAIL',    //E-MAIL
		CommitConfirm:'CommitConfirm',    //确认提交
        new_product_name:'New product',    //新品名称
        more:'MORE',    //更多
	}
}
export default en