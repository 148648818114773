import Cookies from 'js-cookie'
let COOKIE_KEY_ACCESS = "token"
export function getToken() {
    // return Cookies.get(COOKIE_KEY_ACCESS)
    return 'a40ba6c8-b389-4448-8fd4-453fbbada266'
}

export function setToken(token) {
    // console.log('存储成功')
    return Cookies.set(COOKIE_KEY_ACCESS, token, { expires: 280000 })
}

export function removeToken() {
    return Cookies.remove(COOKIE_KEY_ACCESS)
}




let COOKIE_KEY_ACCESS2 = "user"
export function getUser() {
    return Cookies.get(COOKIE_KEY_ACCESS2)
}

export function setUser(user) {
    // console.log('存储成功')
    return Cookies.set(COOKIE_KEY_ACCESS2, user, { expires: 280000 })
}

export function removeUser() {
    return Cookies.remove(COOKIE_KEY_ACCESS2)
}

