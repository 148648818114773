import request from "../utils/request";

//   http://196.192.168.164:9091/fabTank-admin/manager/category/allChildnode            //所有商品的列表   //商品发布列表接口
//  http://196.192.168.164:9100/fabTank-ftfs/commodity/category/allChildnode

export const fabricList = () => {
    return request({
        method: 'get',
        url: '/fabTank-ftfs/commodity/category/allChildnode',

    })
}


//   http://196.192.168.164:9090/fabTank-product/client/commodity/commodity             //列表中通过id获取商品(已更改)
//   http://196.192.168.164:9090/fabTank-product/client/commodity/search/query/commodity             //根据叶子分类id查找商品
//  http://196.192.168.164:9100/fabTank-ftfs/commodity/queryCommodityByCategory

export const commodityList = (idsObj) => {
    return request({
        url: '/fabTank-ftfs/commodity/queryCommodityByCategory',
        method: 'post',
        arg: {
            // "classificationPath": "25,65"
            "classificationPath": idsObj.ids,
            "page": idsObj.current,
            "pageSize": idsObj.pageSize,
            "tagList":idsObj.tagList,
        }
    })
}


//  http://196.192.168.164:9090/fabTank-product/commodity/merchant/get/{commodityId}       //商品详情
//      http://196.192.168.164:9100/fabTank-ftfs/commodity/merchant/query


export const commodityDetail = (id) => {
    return request({
        url: `/fabTank-ftfs/commodity/merchant/query/${id}`,
        // url: 'fabTank-product/commodity/merchant/get/{commodityId}',
        method: 'get',
        // arg:{
        //     "commodityId":id
        // }
    })
}


//    http://196.192.168.164:9090/fabTank-product/inquiry/user/add             //询盘
export const inquiryCommodity = (formLabelAlign) => {
    return request({
        url: '/fabTank-ftfs/user/inquiry/add',
        method: 'post',
        arg: {
            "id": 0,
            "commodityId": formLabelAlign.commodityId,
            "userId": 0,
            "inquiryCount": formLabelAlign.number,
            "inquiryExpectPrice": formLabelAlign.prices,
            "inquiryProblem": formLabelAlign.question,
            "inquirySupplementaryMessage": formLabelAlign.textarea,
            "inquiryContact": formLabelAlign.contact,
            "inquiryCompanyName": formLabelAlign.company,
            "inquiryPhone": formLabelAlign.phone,
            "inquiryLocation": formLabelAlign.address,
            "inquiryFacsimile": formLabelAlign.faxes,
            "inquiryElectronicMail": formLabelAlign.email,
            "inquiryStatus": 0
        }
    })
}


//     http://196.192.168.164:9090/fabTank-product/sample/user/add            //拿样
export const sampleCommodity = (formLabelAlign2) => {
    return request({
        url: '/fabTank-ftfs/user/sample/add',
        method: 'post',
        arg: {
            "id": 0,
            "commodityId": formLabelAlign2.commodityId,
            "userId": 0,
            "inquirySupplementaryMessage": formLabelAlign2.textarea,
            "sampleContact": formLabelAlign2.contact,
            "sampleCompanyName": formLabelAlign2.company,
            "samplePhone": formLabelAlign2.phone,
            "sampleLocation": formLabelAlign2.address,
            "sampleFacsimile": formLabelAlign2.faxes,
            "sampleElectronicMail": formLabelAlign2.email
        }
    })
}

//       http://localhost:9100/fabTank-ftfs/user/login                      //登录 
//       http://196.192.168.164:9100/fabTank-ftfs/user/login
export const LoginApi = (loginForm) => {
    return request({
        url: '/fabTank-ftfs/user/login',
        method: 'post',
        arg: {
            "username": loginForm.username,
            "password": loginForm.password
        }
    })
}


//    http://196.192.168.164:9100/fabTank-ftfs/commodity/query/commodity/label          //首页图片

export const homeListApi = (idsObj) => {
    return request({
        url: '/fabTank-ftfs/commodity/query/commodity/label',
        method: 'post',
        arg: {
            "commodityLabel": idsObj.commodityLabel,                    // 1 新品  2 热门  0 普通
            // "classificationPath": idsObj.ids,
            "page": idsObj.current,
            "pageSize": idsObj.pageSize
        }
    })
}


//  http://localhost:9090/fabTank-product/client/search/keyword          //搜索框
//  http://196.192.168.164:9100/fabTank-ftfs/client/search/keyword

//   http://196.192.168.164:9093/fabTank-search/search/keyword/query   //搜索框接口更改
//   http://196.192.168.164:9100/fabTank-ftfs/es/search/query/keyword
//   http://196.192.168.164:9100/fabTank-ftfs/client/search/keyword     //10.24更改

export const searchKeyWord = (searchObj) => {
    return request({
        url: '/fabTank-ftfs/es/search/query/keyword',
        // url: '/fabTank-ftfs/client/search/keyword',
        method: 'post',
        arg: {
            // "pageNumber": 0,
            // "pageSize": 0,
            // "order": "string",
            // "sort": "string",
            // "notConvert": true,
            "keyword": searchObj.keyWord,
            "pageNumber": searchObj.page,
            "pageSize": searchObj.pageSize
        }
    })
}

//     http://196.192.168.164:9091/fabTank-admin/image/upload           //上传图片
//     'http://196.192.168.164:9100/fabTank-ftfs/image/picture?multipartFile=file' 
export const uploadMultipartFile = (fileList) => {
    return request({
        url: "/fabTank-ftfs/image/picture" + "?categoryNum=shop",
        method: 'post',
        // headers: { 'Content-Type': 'multipart/form-data' },
        arg: {
            // "categoryNum": "shop",
            "multipartFile": fileList
        }
    })
}

//     http://196.192.168.164:9091/fabTank-admin/manager/store/store/add          //店铺入驻 添加店铺
//     'http://196.192.168.164:9100/fabTank-ftfs/manager/store/store/add' \
export const storeAdd = (shopEntryForm) => {
    return request({
        url: "/fabTank-ftfs/manager/store/store/add",
        method: 'post',
        arg: {
            "memberId": shopEntryForm.memberId,
            "storeName": shopEntryForm.storeName,
            "storeLogo": shopEntryForm.storeLogo,
            "storeDesc": shopEntryForm.storeDesc,
            // "storeCenter": "string",   //经纬度
            // "commodityManagementCategory": "string",   //店铺经营类目
            // "selfOperated": true,    //是否自营
            "storeAddressPath": shopEntryForm.storeAddressPath,
            // "storeAddressIdPath": "string",
            "storeAddressDetail": shopEntryForm.storeAddressDetail,
            "companyName": shopEntryForm.companyName,
            "companyPhone": shopEntryForm.companyPhone,
            "companyAddress": shopEntryForm.companyAddress,
            // "companyAddressIdPath": "string",
            "companyAddressPath": shopEntryForm.companyAddressPath,
            "employeeNum": shopEntryForm.employeeNum,
            "registeredCapital": shopEntryForm.registeredCapital,
            "linkName": shopEntryForm.linkName,
            "linkPhone": shopEntryForm.linkPhone,
            "companyEmail": shopEntryForm.companyEmail,
            "licenseNum": shopEntryForm.licenseNum,
            "scope": shopEntryForm.scope,
            "licencePhoto": shopEntryForm.licencePhoto,
            "legalName": shopEntryForm.legalName,
            "legalId": shopEntryForm.legalId,
            "legalPhoto": shopEntryForm.legalPhoto,
            "settlementBankAccountName": shopEntryForm.settlementBankAccountName,
            "settlementBankAccountNum": shopEntryForm.settlementBankAccountNum,
            "settlementBankBranchName": shopEntryForm.settlementBankBranchName,
            "settlementBankJointName": shopEntryForm.settlementBankJointName
        }
    })
}


//   http://196.192.168.164:9091/fabTank-admin/manager/store/store/get/detail/{storeId}      获取店铺详情  获取状态码
//   http://196.192.168.164:9091/fabTank-admin/manager/store/store/get/detail/1701480897364099072
//   'http://196.192.168.164:9100/fabTank-ftfs/manager/store/store/get/detail/1212' \

export const storeDetail = (storeId) => {
    return request({
        url: `/fabTank-ftfs/manager/store/store/get/detail/${storeId}`,
        method: 'get',
    })
}


//  http://172.16.181.201:9100/fabTank-ftfs/client/tag/allTag       查询所有标签列表
export const allTagListApi = () => {
    return request({
        url: '/fabTank-ftfs/client/tag/allTag',
        method: 'get',
    })
}


//  http://172.16.181.201:9100/fabTank-ftfs/client/tag/category/allTag/95   查询某id下绑定的标签信息
export const selectedTagListApi = (id) => {
    return request({
        url: `/fabTank-ftfs/client/tag/category/allTag/${id}`,
        method: 'get',
    })
}


//  http://172.16.182.201:9100/fabTank-ftfs/client/banner/banner        首页 轮播图 查询
export const bannerListApi = () => {
    return request({
        url: "/fabTank-ftfs/client/banner/banner",
        method: 'post',
        arg: {
            // "id": 0,
            // "bannerName": "string",
            // "bannerImage": "string",
            "enable": 0,        //上架的
            // "bannerPath": "string",
            // "bannerOrder": 0
        }
    })
}

//  http://172.16.182.201:9100/fabTank-ftfs/user/footer/find                通过xx查询页脚信息
export const footerInfoApi = (data) => {
    return request({
        url: `/fabTank-ftfs/user/footer/find`,
        method: 'post',
        arg: {
            "footerType": data
        }
    })
}

//   http://172.16.182.201:9100/fabTank-ftfs/user/footer/find/footers      查询所有页脚列表信息
export const footerListApi = () => {
    return request({
        url: "/fabTank-ftfs/user/footer/find/footers",
        method: 'post',
        arg: {}
    })
}

//   http://172.16.182.201:9100/fabTank-ftfs/commodity/cert/find/{id}
export const certListApi = (id) => {
    return request({
        url: `/fabTank-ftfs/commodity/cert/find/${id}`,
        method: 'post',
        arg: {}
    })
}

//  http://172.16.182.201:9100/fabTank-ftfs/club/add/supplier/add  俱乐部添加供应商
export const supplierAddApi = (formData) => {
    return request({
        url: `fabTank-ftfs/club/add/supplier/add`,
        method: 'post',
        arg: formData
    })
}

//   http://172.16.182.201:9100/fabTank-ftfs/club/add/designer/add   俱乐部添加设计师
export const designerAddApi = (formData) => {
    return request({
        url: `fabTank-ftfs/club/add/designer/add`,
        method: 'post',
        arg: formData
    })
}

//   http://172.16.182.201:9100/fabTank-ftfs/pc/expert/add   新增面料专家
export const specialistAddApi = (formData) => {
    return request({
        url: `fabTank-ftfs/pc/expert/add`,
        method: 'post',
        arg: formData
    })
}

//	http://172.16.182.201:9100/fabTank-ftfs/pc/expert/find/page		分页查询面料专家
export const specialistListApi = (formData) => {
    return request({
        url: `fabTank-ftfs/pc/expert/find/page`,
        method: 'post',
        arg: formData
    })
}

//  http://172.16.182.201:9100/fabTank-ftfs/pc/expert/find/goods    查询面料专家发布的选品
export const specialistDetailApi = (formData) => {
    return request({
        url: `fabTank-ftfs/pc/expert/find/goods`,
        method: 'post',
        arg: formData
    })
}