// 请求封装
import axios from "axios";
import qs from 'qs';

import store from '../store/index'

const instance = axios.create({
    // 如果是本地服务器可以直接填地址，否则要配置跨域
    baseURL: '',
	timeout: 5000, //请求超时时间：5秒
	withCredentials: false // 表示跨域请求时是否需要使用凭证
});

// 请求拦截器
instance.interceptors.request.use((config)=>{
    // console.log(config);
    const token = store.getters.getToken;
    if (token) {
        // 已经登录成功，统一添加token
        // config.headers.Authorization = `Bearer ${token}`
        config.headers.token = `${token}`       
    }
    //为请求头对象，添加token验证的Authorization字段
    // config.headers.token = localStorage.getItem("token")
    // config.headers.token = sessionStorage.getItem("token")
    return config;
},err=>{
    console.log(err);
    return err
})
// 响应拦截器
instance.interceptors.response.use(res=>{
    // console.log(res);
    return res;
},err=>{
    console.log(err);
    return err;
})

let request = function({method,url,arg,headerType}){
    let data = {};
    // 如果是get请求如何处理
    if(method.toLowerCase() == 'get'){
        // url 参数处理
        // 请求头
        if(headerType == 'form'){
            instance.defaults['Content-Type'] = "application/x-www-form-urlencoded;charset=utf-8";
        }
        if(arg){
            arg = qs.stringify(arg);
            // url = url + '?' + arg;
            url += "?" +arg;
        }
    }
    // 如果是post请求如何处理
    if(method.toLowerCase() == 'post'){
        // 请求头  请求体的格式
        if(headerType == 'form'){
            instance.defaults['Content-Type'] = "application/x-www-form-urlencoded;charset=utf-8";
            // 对于post请求请求体  提交的数据需要进行序列化
            data = qs.stringify(arg);
        }else {
            instance.defaults["Content-Type"] = "application/json;charset=utf-8";
            data = arg;
        }
    }
    return new Promise((resolve,reject)=>{
        instance({
            method,//请求方式  get post
            url,//基础路径后面的  url/params 对于 url?key=value&key=value
            // params,//query参数
            data//请求体当中的参数
        }).then(res=>{
            // console.log('成功的值'+res);
            resolve(res);
        }).catch(err=>{
            console.log(err);
            reject(err);
        })
    })
}
export default request;