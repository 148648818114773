import { debounce } from './'
window.addEventListener('resize', debounce(function () {
  if (document.documentElement.clientWidth < 750) {
    if (window.location.href.includes ('/mobile.html#/')) return
    // 打开这行后 页面从检查那里 减少页面的宽度 会导致 页面直接跳回首页
    window.location.href = './mobile.html#/'
    // console.log(window.location.href);
    // window.location.reload()
  } else {
    if (window.location.href.includes ('/index.html#/')) return
    // 打开这行后 页面从检查那里 减少页面的宽度 会导致 页面直接跳回首页
    window.location.href = './index.html#/'
    // console.log(window.location.href);
  }
}, 100))
