// 引入vue
import Vue from 'vue'
import {getToken,setToken,getUser,setUser} from "@/utils/cookie.js"
// 引入vuex
import Vuex from 'vuex'
// 引入vuex持久化插件
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
export default new Vuex.Store({
    // 页面打开的时候渲染获取token
    state: {
        // 1. 用来存储登录成功之后，得到的 token
        token: getToken() || '',
        user: getUser() ||''
    },
    getters: {
        getToken(state) {
            return state.token ||  getToken() || "";
        },

        getUser(state) {
            return state.user ||  getUser() || "";
        },
    },
    mutations: {
        // 修改token，并将token存入localStorage
        setToken(state, token) {
            state.token = token;
            setToken(token);
            // console.log('store、localstorage保存token成功！');
        },


        setUser(state, user){
            state.user = user;
            setUser(user);
        },


        delToken(state) {
            state.token = "";
            localStorage.removeItem("token");
        },

        delUser(state) {
            state.username = "";
            localStorage.removeItem("username");
        },
        // 可选
        // setUserInfo(state, userName) {
        //     state.userName = userName;
        // }
    },
    actions: {
        // removeToken: (context) => {
        // context.commit('setToken')
        // }
    },
    // 调用模块化里的数据
    modules: {
    },
    /* vuex数据持久化配置 */
    plugins: [
        createPersistedState({
            // 存储方式：localStorage、sessionStorage、cookies
            storage: window.sessionStorage,
            // // 存储的 key 的key值
            // key: "store",
            render(state) {
                // 要存储的数据
                return { ...state };
            }
        })
    ]
})