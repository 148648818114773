// 中文 
const zh = {
	language: {
		name: '中文'
	},
	navbar: {
		home: '首页',
		introduction: '简介',
		contact: '联系我们',

		//头部
		home:'首页',    //首页
		fabric:'面料库',    //面料库
		buyingInformation:'求购信息',    //求购信息
		information:'资讯',    //资讯
		trend:'流行趋势',    //流行趋势
		cotton:'棉花',    //棉花
		Login:'登录',		//登录
		joinClub: '申请加入俱乐部',		//申请加入俱乐部
		ExpertRecruitment:'面料专家招募',		//面料专家招募
		//登录
		AccountNumber:'账号',		//账号
		Password:'密码',		//密码
		UserNameCannotEmpty:'用户名不能为空',	//用户名不能为空
		PasswordCannotEmpty:'密码不能为空',		//密码不能为空
		ForgetThePassword:'忘记密码',			//忘记密码
		RetrievePassword:'找回密码',			//找回密码
		Service:'请联系逐日客服',				//请联系逐日客服
		Logout:'确定退出登录吗',			//确定退出登录吗
		Cancel:'取消',		//取消
		Confirm:'确定',		//确定

		//首页
		loadMore:'加载更多',    //加载更多

		//底部
		customerService:'客户服务',    //客户服务
		FAQ:'常见问题解答',    //常见问题解答
		unsubscribe:'取消订阅',    //取消订阅
		contacts:'联系人',    //联系人
		about:'关于',    //关于
		BattenRecruitment:'巴藤招聘',     //巴藤招聘
		BattenCampus:'巴藤校园',    //巴藤校园
		KungFuGeek:'功夫极客',    //功夫极客
		legislation:'法律',   //法律
		PrivacyPolicy:'隐私政策',    //隐私政策
		BrowserCachingPolicy:'浏览器缓存政策',    //浏览器缓存政策
		TermsAndConditions:'条款与条件',    //条款与条件
		IOSVersionDownload:'IOS版下载',     //IOS版下载
		AndroidVersionDownload:'安卓版下载',    //安卓版下载
		FabtankMiniProgram:'天下布仓小程序',	//天下布仓小程序
		SuICPFor14053618:'苏ICP备14053618号',    //苏ICP备14053618号

		//面料库
		ActualResult:'全部结果',	//全部结果
		WovenGoods:'梭织类',    //梭织类
		hosiery:'针织类',     //针织类
		SpecialTypes:'特殊类',    //特殊类
		hairgrass:'毛草类',    //毛草类
		Nonwovens:'非织造类',    //非织造类
		NoData:'暂无数据',		//暂无数据
		Loadingzhong:'加载中',		//加载中

		//详情页面
		back:'返回', //返回
        specification: '规\u0020 \u0020格',   //规格
		theWeight:'克 重',   //克重
		theWidth:'门 幅',   //门幅
		theConst:'经纬密',   //经纬密
		client:'供应商',   //供应商
		periodOfValidity:'有效期',   //有效期
		LongTermEffective:'长期有效',	//长期有效
		club:'俱乐部',    //俱乐部
		shippingMethod:'送货方式',    //送货方式
		ByMutualAgreement:'自行约定',	//自行约定
		theContent:'原料成分',   //原料成分
		structure:'布料分类',   //布料分类
		releaseTime:'发布时间',    //发布时间
		ColorCardDownload:'色卡下载',  //色卡下载
		inquiry:'我要询盘',   //我要询盘
		sample:'我要拿样',    //我要拿样
		detailedInformation:'详细信息',    //详细信息
		reset:'重置',   //重置
		Render3DEffect:'渲染3D效果',   //渲染3D效果
		Loading:'拼命加载中',  //拼命加载中
		NewProduct:'同期新品',//同期新品
		ExplosiveGoods:'爆款商品',//爆款商品
		ReadMore:'浏览更多', //浏览更多
		QualityCertificate:'质量认证', //质量认证
		DeliverPlace:'发货地',	//发货地
		ProductionPlace:'原产地',	//原产地
		LogisticsMethods:'物流方式',	//物流方式
		LogisticsTerms:'物流条款',	//物流条款

		//我要询盘
		supertex:'江阴科奇服饰有限公司',	
		ArticleNumber:'商品货号',   //商品货号
		ExpectedOrderQuantity:'预期订货量',    //预期订货量
		QuantityCannotEmpty:'预期订货量不能为空', //预期订货量不能为空
		ExpectedQuantity:'请输入正确的预期订货量', //请输入正确的预期订货量
		ExpectedPrice:'预期价格',    //预期价格
		PriceCannotEmpty:'预期价格不能为空', //预期价格不能为空
		PriceMustRoundNumber:'预期价格必须为整数', //预期价格必须为整数
		question:'问题',    //问题
			//我要拿样(下面部分)
		SupplementaryInformation:'补充信息',    //补充信息
		SupplementaryCannotEmpty:'补充信息不能为空',//补充信息不能为空
		contacts:'联系人',    //联系人
		contactCannotEmpty:'联系人不能为空',//联系人不能为空
		companyName:'公司名称',    //公司名称
		companyNameCannotEmpty:'公司名称不能为空', //公司名称不能为空
		phone:'联系电话',    //联系电话
		contactNumberCannotEmpty:'联系电话不能为空',//联系电话不能为空
		contactNumberMustNumber:'联系电话格式错误',//联系电话必须为数字（改为）联系电话格式错误
		address:'地址',    //地址
		addressCannotEmpty:'地址不能为空',  //地址不能为空
		faxes:'传真',     //传真
		EMAIL:'E-MAIL',    //E-MAIL
		CommitConfirm:'确认提交',    //确认提交
        new_product_name:'新品',
        more:'更多',
	}
}
export default zh