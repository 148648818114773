<template viewport>
  <div id="app">
      <div class="min">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <!-- <router-view></router-view> -->
      </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'Index',
  components: {
    
  },
  data() {
    return {

    }
  },
  methods: {

  },
  // 禁止web端屏幕缩放  滚轮失效
  created() {
    window.addEventListener("mousewheel",function (event) {
      if (event.ctrlKey === true || event.metaKey) {event.preventDefault();}},{ passive: false })
  },
  mounted(){
    // window.addEventListener("beforeunload",function () {
    //   Cookies.remove('token');
    // })
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  background-color: #FFFFFF;
}
</style>

