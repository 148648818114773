// 防抖函数
export function debounce (fn, delay) {
  let timer = null
  return function () {
    const _this = this
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function () {
      fn.apply(_this, args)
    }, delay)
  }
}

// 节流函数
export function throttle (fn, delay) {
  let timer = null
  return function () {
    const _this = this
    const args = arguments
    if (timer) {
      return
    }
    timer = setTimeout(function () {
      fn.apply(_this, args)
      timer = null
    }, delay)
  }
}
