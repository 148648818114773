<template>
    <!-- 选品中心 -->
    <div class="container">
        <PcHeader></PcHeader>
        <div class="container-inner">
            <div class="ul-box" ref="ulBox">
                <ul style="position: relative;">
                    <div v-for="(item, index) in list" :key="index">
                        <li v-show="datalang == '中文简体'"
                            @click="changeActive(index, item), dialogFormVisible = !dialogFormVisible, changeIndex(index), isChoose(index, item)"
                            :class="[index == active ? 'active' : '']">
                            {{ item.categoryName }}<span><img src="../../../assets/images/right-jt.png" alt=""></span>
                        </li>
                        <li v-show="datalang == 'English'"
                            @click="changeActive(index, item), dialogFormVisible = !dialogFormVisible, changeIndex(index), isChoose(index, item)"
                            :class="[index == active ? 'active' : '']">
                            {{ item.categoryNameEn }}<span><img src="../../../assets/images/right-jt.png" alt=""></span>
                        </li>

                        <div v-if="dialogFormVisible && list[index2].children.length > 0" class="dialog_two"
                            :style="{ height: tkHeight }">
                            <template v-if="datalang == '中文简体'">
                                <i class="el-icon-close" @click="close"></i>
                                <el-tree :data="list[index2].children" show-checkbox default-expand-all node-key="id"
                                    ref="tree" highlight-current :props="defaultProps" :filter-node-method="filterNode"
                                    check-on-click-node @check-change="checkChange()" @check="currentChecked">
                                </el-tree>
                            </template>
                            <template v-if="datalang == 'English'">
                                <i class="el-icon-close" @click="close"></i>
                                <el-tree :data="list[index2].children" show-checkbox default-expand-all node-key="id"
                                    ref="tree" highlight-current :props="defaultPropsEn" :filter-node-method="filterNode"
                                    check-on-click-node @check-change="checkChange()" @check="currentChecked">
                                </el-tree>
                            </template>
                        </div>
                    </div>
                </ul>
            </div>
            <div class="right" ref="rightHeight">
                <div class="tags">
                    <p v-if="datalang == '中文简体' && allTagList.length>0">全部结果 > "<span v-for="(item, index) in showTagsList" :key="index"> {{
                        item.tagName }} <span class="item-line">- </span></span>"</p>
                    <p v-if="datalang == 'English' && allTagList.length>0">{{ $t('ActualResult') }} > "<span v-for="(item, index) in showTagsList"
                            :key="index"> {{ item.tagNameEn }} <span class="item-line">- </span></span>"</p>
                    <div v-if="datalang == '中文简体'" class="allTagList" v-for="(item, index) in allTagList" :key="index">
                        <span class="tag-title">{{ item.tagGroupName }}</span>
                        <el-radio-group v-model="selectedOptions[index]" v-for="(item2, index2) in item.tags" :key="index2"
                            @click.prevent.native="getTagName(item, index, item2, index2, $event)" ref="radioGroup">
                            <el-radio :class="selectedItems[index] && selectedItems[index].tagName === item2.tagName? 'selected':''" :label="item2.tagName">{{ item2.tagName }}</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="datalang == 'English'" class="allTagList" v-for="(item, index) in allTagList" :key="index">
                        <span class="tag-title">{{ item.tagGroupNameEn }}</span>
                        <el-radio-group v-model="selectedOptions[index]" v-for="(item2, index2) in item.tags" :key="index2"
                            @click.prevent.native="getTagName(item, index, item2, index2, $event)">
                            <el-radio :class="selectedItems[index] && selectedItems[index].tagNameEn === item2.tagNameEn? 'selected':''" :label="item2.tagName">{{ item2.tagNameEn }}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <ul class="dialog-ul2" v-loading="loading2" :element-loading-text="$t('navbar.Loadingzhong')">
                    <div class="nodata" v-if="this.length < 1">
                        <span v-show="!loading">{{ $t('navbar.NoData') }}!</span>
                        <span v-show="loading"></span>
                    </div>
                    <div v-else v-for="(item, index) in comList.rows" :key="index" class="dialog-ul2-div">
                        <!-- 使用骨架屏 -->
                        <el-skeleton class="el-skeleton" animated :loading="loading">
                            <template slot="template">
                                <el-skeleton-item variant="image" class="sk-img" />
                                <div style="padding: 14px;">
                                    <el-skeleton-item variant="p" style="width: 100%" />
                                </div>
                            </template>
                            <template>
                                <!-- 搜索的时候 -->
                                <div class="el-skeleton-div" @click="gotodetail(item.id)" v-if="searchObj.keyWord">
                                    <img :src="item.originalImage" alt="" class="img" style="object-fit: cover;">
                                    <el-tooltip class="item" effect="light" :content="item.productIdName" placement="top">
                                        <p class="skeleton-p" style="text-align: center;margin: 10px 0;">
                                            <span v-html="item.productIdName"></span>
                                        </p>
                                    </el-tooltip>
                                </div>

                                <!-- 不搜索的时候 -->
                                <div class="el-skeleton-div" @click="gotodetail(item.id)" v-else>
                                    <img :src="item.originalImage" alt="" class="img" style="object-fit: cover;">
                                    <el-tooltip class="item" effect="light" v-if="item.commodityName && item.productId"
                                        :content="item.commodityName + ' ' + item.productId" placement="top">
                                        <p class="skeleton-p" style="text-align: center;margin: 10px 0;">
                                            <span v-html="item.commodityName"></span> {{ item.productId }}
                                        </p>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="light"
                                        v-else-if="item.commodityName && !item.productId" :content="item.commodityName"
                                        placement="top">
                                        <p class="skeleton-p" style="text-align: center;margin: 10px 0;">
                                            <span v-html="item.commodityName"></span> {{ item.productId }}
                                        </p>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="light"
                                        v-else-if="!item.commodityName && item.productId" :content="item.productId"
                                        placement="top">
                                        <p class="skeleton-p" style="text-align: center;margin: 10px 0;">
                                            <span v-html="item.commodityName"></span> {{ item.productId }}
                                        </p>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-skeleton>

                        <!-- 不使用骨架屏 -->
                        <!-- <div class="img-name" @click="gotodetail(item.id)">
                            <img :src="item.smallImage" alt="" class="img">
                            <el-tooltip class="item" effect="light" :content="item.commodityName + ' ' + item.productId"
                                placement="top">
                                <span>{{ item.commodityName }} {{ item.productId }}</span>
                            </el-tooltip>
                        </div> -->
                    </div>
                </ul>
                <div class="page">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :page-size="this.idsObj.pageSize"
                        :current-page="searchObj.keyWord ? this.searchObj.page : this.idsObj.current"
                        layout="total, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <PcFooter></PcFooter>
    </div>
</template>

<script>
import PcHeader from "../../../components/pc/Header.vue"
import PcFooter from "../../../components/pc/Footer.vue"
import { fabricList, commodityList, allTagListApi,selectedTagListApi } from "../../../api/index";
export default {
    components: {
        PcHeader,
        PcFooter,
    },
    data() {
        return {
            new_product:96,
            selectedOptions: [],
            selectedItems: [],
            showTagsList: [],
            tagName: '',//搜索结果
            allTagList: [], //所有标签列表
            loading: true,
            loading2: true,
            list: [],
            dialogFormVisible: false,
            isChange: 0,
            index: 0,
            index2: 0,
            active: null,
            idss: '',        //当前选中的选品中心下的一级分类
            defaultProps: {
                children: 'children',
                label: 'categoryName'
            },
            defaultPropsEn: {
                children: 'children',
                label: 'categoryNameEn'
            },
            length: 0,
            total: 0,       //总条数
            pageSize: 0,    //每页多少条
            idsObj: {
                current: 1,     //当前第几页
                ids: null,          //列表选择商品 传入的商品ID  默认进入页面展示的数据
                pageSize: 18,
                tagList: []
            },
            msg: '',
            searchObj: {
                keyWord: '',
                page: 1,
                pageSize: 18
            },
            datalang: '',   //路由语言判断
            tkboxheight: 1035,
            isRefresh: false,
        }
    },
    // 自定义指令 实现可拖动
    directives: {
        drag(el, bindings) {
            el.onmousedown = function (e) {
                var disx = e.pageX - el.offsetLeft
                var disy = e.pageY - el.offsetTop
                document.onmousemove = function (e) {
                    el.style.left = e.pageX - disx + 'px'
                    el.style.top = e.pageY - disy + 'px'
                }
                document.onmouseup = function () {
                    document.onmousemove = document.onmouseup = null
                }
            }
        }
    },
    watch: {
        $route(to, from) {
            if (to.path !== '') {
                this.dialogFormVisible = false
                // console.log('跳转页面，关闭弹框！')
            }
            // console.log('to.path',to.path);
            // console.log('from.path',from.name);
            if (to.path == '/apparel' && from.name !== 'appareldetail') {
                // console.log('触发了吗？');
                this.active = 0
                this.idsObj.ids = 108
                this.getcommodityList(this.idsObj)
            }
        },
        isRefresh() {
            this.$EventBus.$on("isRefresh", (isRefresh) => {
                this.isRefresh = isRefresh
                // console.log('isRefresh', isRefresh);
                if (this.isRefresh == true) {
                    // console.log("该刷新页面了！");
                    this.active = null
                    this.idsObj.current = 1        //页数重置
                    this.idsObj.ids = 2
                    this.idsObj.tagList = []
                    this.showTagsList = []
                    this.selectedOptions = []
                    this.selectedItems = []
                    this.refreshTree()
                    this.dialogFormVisible = false
                    this.getcommodityList(this.idsObj)
                    this.getSelectedTagList(this.idsObj.ids)
                }
            })
        }
    },
    computed: {
        //将弹框高度和ul-box高度绑定
        tkHeight: function () {
            // return this.tkboxheight + 'px';
            // console.log('this.$refs.ulBox.offsetHeight',this.$refs.ulBox.offsetHeight);
            return this.$refs.ulBox.offsetHeight + 'px';
        }
    },
    methods: {
        refreshTree() {
            if(this.active){
                this.$refs.tree[this.index2].setCheckedKeys([]);
            }
            // this.$refs.tree[this.index2].setCheckedKeys([]);
        },
        //处理全部结果 
        getTagName(item, index, item2, index2, event) {
            // console.log(item, index, item2, index2, event)
            if (!this.selectedItems[index]) {
                this.selectedItems[index] = {
                    id: '',
                    tagName: '',
                    tagNameEn: ''
                }
            }
            //取消选中
            // console.log('event', event);
            // console.log('event', event.target.innerText);
            // console.log('selectedItems', this.selectedItems);
            if (this.selectedItems[index].tagName === event.target.innerText || this.selectedItems[index].tagNameEn === event.target.innerText) {
                // 如果是，则取消选中状态
                // console.log('消选中状态');
                this.selectedItems[index].id = '';
                this.selectedItems[index].tagName = '';
                this.selectedItems[index].tagNameEn = '';
            } else {
                item.tags.forEach((item) => {
                    if (event.target.innerText == item.tagName || event.target.innerText == item.tagNameEn) {
                        this.selectedItems[index].id = item.id;
                        this.selectedItems[index].tagName = item.tagName;
                        this.selectedItems[index].tagNameEn = item.tagNameEn;
                    }
                })
            }
            let newArr = this.selectedItems.filter(function (item, index) {
                return item.id !== null && item.id !== '';
            })
            this.showTagsList = JSON.parse(JSON.stringify(newArr))
            this.idsObj.tagList = newArr.map(item => parseInt(item.id));
            this.getcommodityList(this.idsObj)
        },
        //获取所有标签
        // async getAllTagList() {
        //     let { data: res } = await allTagListApi()
        //     // console.log('获取所有标签',res);
        //     this.allTagList = res.result
        //     // console.log('this.allTagList', this.allTagList);
        // },
        //根据id获取分类下绑定的标签
        async getSelectedTagList(id) {
            let {data:res} = await selectedTagListApi(id)
            this.allTagList = res.result
        },
        close() {
            this.dialogFormVisible = false
        },
        //重复点击li取消弹框    点击二级列表渲染所有商品（需分页处理）
        isChoose(index, item) {
            if (index != this.isChange) {
                this.isChange = index;
                this.dialogFormVisible = true
            }
        },
        //li和右侧内容对应起来
        changeIndex(index) {
            this.index2 = index
        },
        changeActive(index, item) {
            this.active = index;
            // console.log('index==', index, 'item==', item);
            this.index = index
            this.idsObj.ids = item.id
            this.idsObj.current = 1        //页数重置
            this.getcommodityList(this.idsObj)
            this.getSelectedTagList(this.idsObj.ids)
            this.idss = item.id
        },
        async getfabricList() {
            //调取所有商品列表的接口
            let { data: res } = await fabricList();
            // console.log('res.result', res.result)
            //根据id查找为面料的接口展示
            var obj = {};
            res.result.forEach((item) => {
                if (item.id == this.new_product) {
                    obj = item
                }
                return obj
            })
            // console.log('obj', obj)
            // console.log(res.result.categoryName)
            this.list = obj.children
        },
        //输入关键字进行过滤
        filterNode(value, list) {
            if (!value) return true;
            return list.categoryName.indexOf(value) !== -1;
        },
        checkChange() { },
        currentChecked(nodeObj, SelectedObj) {
            // console.log(nodeObj)
            // console.log(SelectedObj)
            // console.log(SelectedObj.checkedKeys)   // 这是选中的节点的key数组
            // console.log(SelectedObj.checkedNodes)  // 这是选中的节点数组

            var arr = SelectedObj.checkedKeys
            if (arr.toString() == '') {
                this.idsObj.ids = this.idss
            } else {
                this.idsObj.ids = arr.toString()
            }
            this.idsObj.current = 1        //页数重置
            this.searchObj.page = 1       //页数重置
            this.getcommodityList(this.idsObj)
        },
        //通过id调用商品   及分页设置
        async getcommodityList(idsObj) {
            this.msg = ''
            this.searchObj.keyWord = ''
            let { data: res } = await commodityList(idsObj);
            // console.log('通过id调用商品   及分页设置', res.result)
            if (res.result == null) {
                console.log("请勾选商品！")
                return
                // this.idsObj.ids = 107
                // this.getcommodityList(this.idsObj)
            } else {
                this.comList = res.result
                this.length = res.result.rows.length
                // console.log(this.comList)
                this.total = this.comList.totalCount * 1
                // console.log(this.total)
                this.idsObj.current = this.comList.page * 1
                // console.log(this.idsObj.current)
                // this.pager = Math.floor((this.comList.total - 1) / 10) + 1
                // console.log(this.pager, "页数")
            }
            // this.loading = false
            this.loading = true
            this.loading2 = false
            setTimeout(() => (this.loading = false), 500)
        },
        //分页
        handleCurrentChange(page) {
            // console.log(`当前页: ${page}`);

            if (this.msg) {
                this.searchObj.page = page
                // console.log(this.searchObj.page)
                this.getsearchKeyWord(this.searchObj)
                var pages = page + 1   //让搜索框搜索的商品列表在第一页也能刷新页面
                this.$EventBus.$emit("pages", pages);
            } else {
                this.idsObj.current = page
                this.getcommodityList(this.idsObj)
                this.$EventBus.$emit("page", page);
            }
            this.$EventBus.$emit("page", page);
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        gotodetail(id) {
            this.$router.push({ path: `/appareldetail/${id}` })
        },
    },
    //页面初始化加载商品
    created() {
        this.idsObj.ids = this.new_product
        this.getcommodityList(this.idsObj)
        this.getSelectedTagList(this.idsObj.ids)

        this.$EventBus.$on('this.languageValue', (data) => {
            this.datalang = data
            // console.log('xxxxxxx',this.datalang);
        })
    },
    mounted() {
        this.getfabricList()
        // this.getAllTagList()

        this.$EventBus.$on("isRefresh", (isRefresh) => {
            this.isRefresh = isRefresh
            // console.log('isRefresh', isRefresh);
        })
    },
    beforeDestroy() {
        //移除监听事件
        this.$EventBus.$off("isRefresh")
    },
}
</script>

<style lang="scss" scoped>
/deep/.el-tree-node__label {
    font-size: 20px;
    padding: 18px 0;
}

/deep/.el-tree-node__content {
    height: 35px;
    margin: 3px 0;
    background-color: #F4F4F4;
}

.el-input {
    margin: 16px 0;
}

/deep/ .el-tree-node.is-current>.el-tree-node__content {
    background: rgba(24, 24, 24, 0.2);
    // border-right: 3px solid #1677ff;
    color: #FF235A;
}

/deep/ .el-tree-node.is-current {
    border-radius: 4px;
}

.el-tree {
    margin: 15px 0;
    // height: 938px;
    // height: 980px;
    background-color: #F4F4F4;
    // background-color: red;
    // overflow-y: scroll;
}

/deep/.el-tree {
    .el-checkbox__inner,
    span.el-checkbox__inner {
        // 未选中的 checkbox 样式
        width: 18px;
        height: 18px;
        border-radius: 5px;
    }

    .is-checked .el-checkbox__inner,
    .is-checked span.el-checkbox__inner {
        // 选中的 checkbox 样式
        background-color: #FF235A;
        border: none;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }

    .el-checkbox__inner::after {
        top: 2px;
        left: 6px;
    }

    .is-indeterminate .el-checkbox__inner {
        // 半选中的 checkbox 样式
        background-color: #FF235A;
        width: 16px;
        height: 16px;
        border: none;
    }
}

/deep/.el-dialog.is-fullscreen {
    background-color: #F4F4F4;
}

/deep/.el-tree .is-indeterminate .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border-radius: 5px;
}

/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
    top: 6px;
}

.el-pagination {
    width: 100%;
    text-align: center;
    margin: 50px 0;
}

.container {
    width: 100%;

    // background-color: aqua;
    .container-inner {
        // max-width: 1920px;
        // min-height: 1410px;
        // background-color: bisque;
        margin: auto;
        display: flex;

        .ul-box {
            background-color: #F4F4F4;

            ul {
                // width: 254px;
                width: 300px;
                height: 1011px;
                // background-color: #F4F4F4;
                margin-top: 24px;
                cursor: pointer;

                li {
                    // width: 100%;
                    height: 46px;
                    // box-sizing: border-box;
                    // margin: 10px 0 9px 0;
                    // padding-left: 44px;
                    font-size: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 31px 0 44px;

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                .dialog_two {
                    position: absolute;
                    top: -24px;
                    left: 310px;
                    width: 340px;
                    // height: 1034px;
                    // height: 1314px;
                    // height: calc(100%-360px);
                    // height: 100vh;
                    background-color: #F4F4F4;
                    // background-color: red;
                    // position: fixed;
                    // background-color: #F4F4F4;
                    // margin-top: 24px; 
                    cursor: pointer;
                    // margin: 108px 0 0 310px;
                    z-index: 9999;

                    .el-icon-close {
                        font-size: 20px;
                        width: 20px;
                        height: 20px;
                        // background-color: #7E73E0;
                        margin: 15px 0 0 305px;
                    }

                    .el-icon-close:hover {
                        color: blue;
                    }
                }

                .active {
                    // width: 100%;
                    height: 46px;
                    background-color: #3D3D3D;
                    color: #FFFFFF;
                    // background-color: #e9e6ff;
                    // color: #7E73E0;
                }
            }
        }

        .right {
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            flex-direction: column;
            position: relative;
            padding-bottom: 132px;

            // background-color: aqua;
            .tags {
                margin: 20px 15px 0 15px;
                font-size: 18px;

                p {
                    margin-bottom: 15px;

                    span:nth-last-child(1) {
                        .item-line {
                            display: none;
                        }
                    }
                }

                .allTagList {
                    height: 50px;
                    line-height: 50px;
                    border-top: 1px solid #D8D8D8;
                    display: flex;

                    .tag-title {
                        min-width: 140px;
                        background: #F4F4F4;
                        text-align: center;
                    }

                    // .item-tags {
                    //     // background-color: aqua;
                    //     min-width: 140px;
                    //     text-align: center;
                    //     cursor: pointer;
                    //     color: #074787;
                    // }
                    /deep/.el-radio-group {
                        .el-radio {
                            line-height: 50px;
                            min-width: 140px;
                            text-align: center;
                            cursor: pointer;
                            color: #074787;

                            .el-radio__input {
                                display: none;
                            }

                            .el-radio__label {
                                font-size: 18px;
                            }
                            .selected{
                                color: #ec0808;
                            }
                        }
                    }
                    .selected{
                        color: #ec0808 !important;
                    }
                }

                .allTagList:nth-last-child(1) {
                    border-bottom: 1px solid #D8D8D8;
                }
            }

            /deep/.el-dialog {
                background-color: #F4F4F4;
            }

            .img {
                width: 240px;
                height: 240px;
            }

            .dialog-ul2 {
                // flex: 1;
                display: flex;
                // min-height: 900px;
                // height: 100%;
                align-items: start;
                // justify-content:space-between;
                flex-wrap: wrap;
                margin-top: 20px;
                font-size: 16px;
                // min-height:916px;
                // align-items: flex-start;
                // justify-content: center;
                // flex-direction: column;
                // justify-content: flex-start;
                list-style: none;
                // height: 40%;
                // overflow: hidden;
                // position: relative;

                // li {
                //     display: flex;
                // }
                // span{
                //     margin-top:-100px;
                // }
                .nodata {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    min-height: 883px;
                }

                .dialog-ul2-div {
                    display: flex;
                    justify-content: flex-start;
                    // background-color: aqua;

                    // height:400px;
                    .el-skeleton {
                        width: 240px;
                        margin: 0 12px;
                        height: 294px;
                        // background-color: blue;

                        .sk-img {
                            width: 240px;
                            height: 240px;
                        }
                    }

                    .skeleton-p {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                        text-align: center;
                    }

                    .img-name {
                        width: 250px;
                        height: 280px;
                        margin: 20px 0 0 10px;
                        display: flex;
                        flex-direction: column;
                        // align-content: space-evenly;
                        align-items: center;
                        justify-content: center;

                        .img {
                            width: 240px;
                            height: 240px;
                        }

                        span {
                            width: 240px;
                            height: 25px;
                            margin-top: 20px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: center;
                        }
                    }
                }
            }

            .page {
                width: 100%;
                height: 132px;
                position: absolute;
                bottom: 0;
                left: 0;
                // background-color: rgb(21, 196, 196);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>